body {
  margin: 0;
  font-family: 'Figtree', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
   /* font-family: 'Poppins', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  /* font-family: 'Noto Sans', sans-serif; */
  background-color: #fff;
  color: #fff;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

::-webkit-input-placeholder  { 
  color:#fff; 
  font-weight: 300;
  font-size: 22px;
}
input:-moz-placeholder { 
  color:#fff; 
  font-weight: 300;
  font-size: 22px;
}
textarea:-moz-placeholder { 
  color:#fff; 
  font-weight: 300;
  font-size: 22px;
}

html {
  scroll-behavior: smooth;
}

.TextSchedule {
  font-family: 'gok_stencilregular', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ButtonTeste {
  width: 200px;
  height: 200px
}

img {
  width: 100%;
  height: auto
}




::-webkit-input-placeholder  { 
  color:#fff; 
  font-weight: 300;
  font-size: 22px;
}
input:-moz-placeholder { 
  color:#fff; 
  font-weight: 300;
  font-size: 22px;
}
textarea:-moz-placeholder { 
  color:#fff; 
  font-weight: 300;
  font-size: 22px;
}

.AlertaJs {
  width: 350px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.TextMensagem {
  margin-bottom: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.TextMensagem2 {
  color: #ffff;
  font-weight: 500;
  font-size: 17px;
  padding-left: 8%;
}

.TextMensagem3 {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.TextMensagem4 {
  font-size: 19px;
  color: #fff;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.BotaoAcessarBD {
   width: 140px;
    height: 32px;
    font-weight: bold;
    background-color: #fff;
    border: 0px;
    border-radius: 8px;
    cursor: pointer;
}

.IframeBanner {
  width: 100%;
  height: 49vw;
}

.SliderGaleria .slick-prev {
  width: 71px;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0px;
}

.SliderGaleria .slick-next {
  width: 71px;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
  right: 0px;
}

.SliderGaleria .slick-prev:before,.SliderGaleria .slick-next:before {
  font-size: 34px;
  color: #ad8666;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.SliderVideo .slick-prev {
  display: none !important;
}

.SliderVideo .slick-next {
  display: none !important;
}

.slick-dots li {
  width: 115px !important; 
  height: 11px !important;
  margin: 0px !important;
  cursor: pointer !important;
  margin-right: 25px !important;
  border: 2px solid #ad8666 !important;
}

.slick-dots li:hover {
  background-color: #ad8666 !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0 !important;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 0 !important;
}


.slick-dots li button {
  height: 12px !important;
  padding: 0px !important;
  width: 100% !important;
}

.slick-dots {
  display: flex !important;
  bottom: -40px !important;
}

.IframeVideo {
  width: 70%;
  height: 31vw;
}

.FormContato {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.InputForm {
  height: 75px;
  background-color: transparent;
  border: 2px solid #a88263;
  padding-left: 20px;
  margin-top: 15px;
  color: #fff;
  font-size: 22px;
}

.InputTextArea {
  height: 220px;
  margin-top: 15px;
  padding-left: 20px;
  padding-top: 15px;
  font-size: 17px;
  background-color: transparent;
  border: 2px solid #a88263;
  color: #fff;
  font-size: 22px;
}

.BotaoEnvia {
  display: flex;
  width: 100%;
  background-color: #ad8666;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
  height: 80px;
  border: 0px;
  margin-top: 20px;
  cursor: pointer;
}

.TextEnviar {
  font-size: 28px;
  font-weight: 800;
  color: #fff;
}

.ImgIconSeta {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #F4F4F4;
  margin-left: 40px;
  border-radius: 12px;
}
::-webkit-scrollbar {
  width: 6px;
  background: #F4F4F4;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb {
  background: #ad8666;
  border-radius: 12px;
}

#Agenda {
  position: absolute;
  margin-top: -80px;
}

#Fotos {
  position: absolute;
  margin-top: -80px;
}

#Videos {
  position: absolute;
  margin-top: -110px;
}

#Contato {
  position: absolute;
  margin-top: -85px;
}

@media (max-width: 1200px) {
  .SliderGaleria .slick-prev {
    width: 45px;
  }
  
  .SliderGaleria .slick-next {
    width: 45px;
  }

  .SliderGaleria .slick-prev:before,.SliderGaleria .slick-next:before {
    font-size: 25px;
  }

  .InputForm {
    height: 55px;
  }

  .InputTextArea {
    height: 160px;
  }
}

@media (max-width: 900px) {
  .IframeVideo {
    width: 100%;
    height: 48vw;
    margin-top: 30px;
  }

  .BotaoEnvia {
    height: 55px;
  }
}


@media (max-width: 500px) {
  .SliderGaleria .slick-prev {
    width: 34px;
  }
  
  .SliderGaleria .slick-next {
    width: 34px;
  }

  .SliderGaleria .slick-prev:before,.SliderGaleria .slick-next:before {
    font-size: 19px;
  }


}

@media (max-width: 480px) {
.SliderBanner {
  padding-top: 90px;
}

.SliderImage .slick-next {
  right: -7vw!important;
}

.SliderImage .slick-prev {
  left: -8vw!important;
}
}